.background {
  background-image: url("/assets/Background.jpg");
  background-color: #cccccc;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  overflow-y: hidden;
}
.msdc {
  position: relative;
  top: 10%;
  font-weight: bolder;
  font-size: 4vw;
}
.addr {
  position: relative;
  top: 10%;
  font-weight: bold;
}
.reg {
  position: fixed;
  bottom: 8px;
}
.name {
  position: fixed;
  bottom: 60px;
}
.desg {
  position: fixed;
  bottom: 35px;
}
.logo {
  position: relative;
  top: 60px;
  height: 200px;
  width: 200px;
}
